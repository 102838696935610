const init_user = {
  name : "",
  score : 0,
  record : []
}

const lodash = require("lodash");

let idx = 0;
let score = 0;
let tmp_state = [];

const user = (state = [init_user], action) => {
  switch(action.type) {
    case "RESET" :
      return []
    case "SET_PEOPLE" :
      return [...state, {name : action.name, score : 0, record : []}]
    case "RECORD" :
      idx = action.idx
      const floor = action.floor

      tmp_state = lodash.cloneDeep(state)
      tmp_state[idx].record = [...tmp_state[idx].record, floor]
      return tmp_state
    case "ADD_SCORE" :
      idx = action.idx;
      score = action.score;
      tmp_state = lodash.cloneDeep(state)
      tmp_state[idx].score += score;
      return tmp_state;
    case "MINUS_SCORE" :
      idx = action.idx;
      score = action.score;
      tmp_state = lodash.cloneDeep(state)
      tmp_state[idx].score -= score;
      return tmp_state;
    case "HIDDEN_COR" :
      idx = action.idx;
      tmp_state = lodash.cloneDeep(state)
      tmp_state[idx].score += 10;
      return tmp_state;
    case "HIDDEN_WRG" :
      idx = action.idx;
      tmp_state = lodash.cloneDeep(state)
      tmp_state[idx].score -= 5;
      return tmp_state;
    default :
      return state;
  }
}

export default user