import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import user from "./user";
import round from "./round";
import voca from "./voca";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

const rootReducer = combineReducers({
  user,
  round,
  voca
})

const persistConfig = {
  key : 'root',
  storage,
  whitelist : ["user", "round", "voca"]
}
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer : persistedReducer,
  middleware : getDefaultMiddleware => getDefaultMiddleware({serializableCheck:false})
})

export default store;