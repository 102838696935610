import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const Hidden = () => {
  const data = useSelector(state => state.user);
  const voca = useSelector(state => state.voca);
  const dispatch = useDispatch();
  const inputRefs = useRef([]);
  const navigate = useNavigate();

  const submit = () => {
    const real_voca = voca.replace(/ /g, '');
    for (let i = 0; i < inputRefs.current.length; i++) {
      if (real_voca === inputRefs.current[i].value) {
        dispatch({type : "HIDDEN_COR", idx : i})
      } else if(inputRefs.current[i].value === "") {
        continue
      } else {
        dispatch({type : "HIDDEN_WRG", idx : i})
      }
    }
    navigate('/result')
  }
  return (
    <section>
      <h2>
        히든 단어 맞추기
      </h2>

      <div>
        {data.map((item, key) => (
          <div key={key}>
            {item.name}
            <input ref={el => inputRefs.current[key] = el}/>
          </div>
        ))}
      </div>
      
      <button onClick={() => submit()}>결과확인</button>
    </section>
  )
}

export default Hidden;