import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

const Round = () => {
  const navigate = useNavigate();
  const round = useParams().idx;
  const dispatch = useDispatch();
  const data = useSelector(state => state.user);
  // const [minscore, setMinscore] = useState(Number.MAX_SAFE_INTEGER);
  let min_score = Number.MAX_SAFE_INTEGER;

  const inputRefs = useRef([]);

  const floor_list = [[]]
  for(let i = 0; i < Math.max(6, data.length-2); i ++) {
    floor_list.push([])
  }
  // 라운드 넘어가면 초기화 되는지 확인하기

  const score = () => {
    const record = floor_list;
    let first_floor = 0;

    // 1층을 제외한 점수 계산
    for (let i = record.length - 1;i > 1; i--) {
      const floor_record = record[i]
      if (floor_record.length > 0) {
        const sc = parseInt(i / floor_record.length);
        first_floor += i % floor_record.length;
        // setMinscore(Math.min(sc, minscore));
        min_score = Math.min(sc, min_score);

        for (let j = 0; j < floor_record.length; j++) {
          dispatch({type: "ADD_SCORE", idx : floor_record[j] , score : sc})
        }
      } else {
        first_floor += i
      }
    }
    // 1층 계산
    if(record[1].length === 0) {
      console.log("anything")
    }
    else if (record[1].length === 1) {
      // if(first_floor < minscore) {
      //   setMinscore(first_floor);
      // }
      min_score = Math.min(first_floor, min_score)
      dispatch({type: "ADD_SCORE", idx : record[1][0] , score : first_floor})
    } else {
      for(let i = 0; i < record[1].length ; i++) {
        const sc = parseInt(first_floor / record[1].length) 
        // if(sc < minscore) {
        //   setMinscore(sc * -1);
        // } 
        min_score = Math.min(min_score, sc)
        dispatch({type: "MINUS_SCORE", idx : record[1][i] , score : sc})
      }
    }
  }

  const submit = () => {
    for (let i = 0;i<inputRefs.current.length;i++) {
      const floor = parseInt(inputRefs.current[i].value);
      floor_list[floor] = [...floor_list[floor], i]
      dispatch({type : 'SET_FLOOR', round, idx : i, floor})
      dispatch({type : 'RECORD', idx : i, floor})
    }
    score()
    dispatch({type : 'SET_MIN', score : min_score, round })
  }

  const max_val = Math.max(data.length - 2, 6);

  useEffect(() => {
    for(let i =0; i < inputRefs.current.length; i ++) {
      inputRefs.current[i].value = ""
    }
    // setMinscore(Number.MAX_SAFE_INTEGER)
  }, [round])

  return (
    <section>
      <h2>{round} Round</h2>

      <h3>플레이어 하차 층수</h3>
      {data.map((item, key) => (
        <div key={key}>
          {item.name}
          {/* default 값 설정 */}
          <input type='number' min='1' max={max_val} ref={el => inputRefs.current[key] = el}/>
        </div>
      ))}

      <button onClick={() => {submit(); navigate(`/round_result/${round}`)}}>라운드 결과 보기</button>
    </section>
  )
}

export default Round;