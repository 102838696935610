import { disassembleHangul } from "es-hangul";
import { useRef, useState, useEffect} from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

const ResultRound = () => {
  const round = useParams().idx;
  const data = useSelector(state => state.user);
  const voca = useSelector(state => state.voca);
  const record = useSelector(state => state.round);
  const navigate = useNavigate();
  const inputRefs = useRef([]);
  const resultRefs = useRef([]);

  const check = (idx) => {
    const floor = data[idx].record[round - 1]; // 플레이어가 내린 층
    const input_num = parseInt(inputRefs.current[idx].value); // 유추한 값

    const sep_voca = disassembleHangul(voca);
    console.log(record[round - 1].floor[floor])
    if (input_num === record[round - 1].floor[floor].length) {
      resultRefs.current[idx].innerText = sep_voca[floor - 1]
    } else {
      resultRefs.current[idx].innerText = "오답"
    }
  }

  const [ischecked, setIschecked] = useState(false)

  const setmin = () => {
    window.open(`/round_result_player/${round}`, '_blank', 'rel=noopener noreferrer')
  }

  useEffect(() => {
    for(let i =0; i < inputRefs.current.length; i ++) {
      inputRefs.current[i].value = ""
      resultRefs.current[i].innerText = ""
    }
  }, [round])

  return (
    <section>
      <h2>{round}라운드 결과</h2>
      <button onClick={() => setmin() }>라운드 결과 보여주기</button>

      <div>
        <input type='checkbox' onChange={() => setIschecked(!ischecked)}/> 현재 점수 확인하기
        {ischecked &&
          <div>
            {data.map((item, key) => (
              <div key={key}>
                {item.name} : {item.score}점
              </div>
            ))}
          </div>
        }
      </div>

      <h3>알파벳 정보 얻기</h3>
      {data.map((item, key) => (
        <div key={key}>
          {item.name} {data[key].record[round]}
          {/* default 값 설정 */}
          <input type="number" min="0" ref={el => inputRefs.current[key] = el}/>
          <button onClick={() => check(key)}>확인하기</button>
          <span ref={el => resultRefs.current[key] = el}></span>
        </div>
      ))}

      {round === '4' ?
        <button onClick={() => navigate(`/hidden`)}>히든단어 맞추기</button>
        :
        <button onClick={() => navigate(`/round/${Number(round) + 1}`)}>다음 라운드</button>
      }

    </section>
  )
}

export default ResultRound;