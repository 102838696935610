import { useSelector } from "react-redux";
import { background } from "../assets";
import { useEffect, useState } from "react";

const Result = () => {
  const data = useSelector(state => state.user);

  const style = {
    width : '100vw',
    height : '100vh',
    position : 'absolute',
    top : '0',
    left : '0',
    background : `no-repeat center/cover url(${background})`,
    display : 'flex',
    flexDirection : 'column',
    justifyContent : 'center',
    alignitems : 'center'
  }

  const showstate = [];
  for(let i =0;i < data.length; i++) {
    showstate.push(false)
  }
  const [show, setShow] = useState(showstate)

  const getScore = (key) => {
    show[key] = true
    setShow([...show])
  }

  useEffect(() => {
    console.log(show)
  }, [show])

  return (
    <section style={style}>
      <h2 style={{'fontSize' : '60px', 'textAlign' : 'center'}}>
        결과
      </h2>
      <div>
        {(data.sort((a, b) => b.score - a.score)).map((item, key) => (
          <div key={key} style={{textAlign : 'center', fontSize : '40px'}}>
            <span onClick={() => getScore(key)}>
              [{key+1}위] &nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            <span style={show[key] ? {visibility : 'visible'}:{visibility : 'hidden'}}>
              {item.name}&nbsp; : &nbsp;
              {item.score}점
            </span>
          </div>
        ))}
      </div>
    </section>
  )
}

export default Result;