import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div>
      Home
      <Link to='/setting'>시작하기</Link>
    </div>
  )
}

export default Home;