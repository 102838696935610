const voca = (state = "", action) => {
  switch(action.type) {
    case "SET_VOCA" :
      return action.voca;
    case "SET_VOCA_RAN" :
      let voca = action.voca;
      const num = action.num - 8;
      for (let i = 0; i<num; i++) {
        let idx = Math.floor(Math.random() * voca.length);
        voca = voca.slice(0, idx) + " " + voca.slice(idx)
      }
      return voca
    default :
      return state;
  }
}

export default voca;