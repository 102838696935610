import {background} from '../assets';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const Result = () => {

  const data = useSelector(state => state.round);
  const round = useParams().idx;

  const style = {
    width : '100vw',
    height : '100vh',
    position : 'absolute',
    top : '0',
    left : '0',
    background : `no-repeat center/cover url(${background})`,
    display : 'flex',
    flexDirection : 'column',
    justifyContent : 'center',
    alignitems : 'center'
  }

  const score = data[round - 1].min_score;

  return (
    <section style={style}>
      <div>
        <h1 style={{textAlign : 'center', fontSize : '60px'}}>{round}라운드 결과</h1>
        <h2 style={{textAlign : 'center', fontSize : '70px'}}>
          최하위 플레이어의 점수 : {score} 점
        </h2>
      </div>
    </section>
  )
}

export default Result;