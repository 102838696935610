import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Setting = () => {

  const navigate = useNavigate();

  const [parti, setParti] = useState(0);
  const inputRefs = useRef([]);
  const [voca, setVoca] = useState("");

  const dispatch = useDispatch();

  const submit = () => {
    dispatch({type : 'RESET'})
    if (parti > 8) {
      dispatch({type : 'SET_NUM', num : parti})
      dispatch({type : "SET_VOCA_RAN", voca, num:parti})
    } else {
      dispatch({type : 'RESET_ROUND'})
      dispatch({type : "SET_VOCA", voca})
    }
    for (let i = 0; i < parti ; i++) {
      const name = inputRefs.current[i].value;
      dispatch({type : 'SET_PEOPLE', name})
    }
    navigate('/round/1')
  }

  return (
    <div>
      <div>
        <h3>참여자 입력 : </h3>
        <input type="number" onChange={(e) => setParti(e.target.value)} value={parti} min="0"/>
      </div>

      <div>
        {Array.from({length:parti}).map((_, key) => (
          <input key={key} ref={el => inputRefs.current[key] = el}/>
        ))}
      </div>

      <h3>히든 단어 :</h3>
      <input onChange={e => setVoca(e.target.value)} maxLength="6"/>
      <button onClick={() => {submit()}}>시작하기</button>
    </div>
  )
}

export default Setting;