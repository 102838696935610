const init_round = [
  {
    round : 1,
    floor : [[],[],[],[],[],[],[]],
    min_score : 0
  },
  {
    round : 2,
    floor : [[],[],[],[],[],[],[]],
    min_score : 0
  },
  {
    round : 3,
    floor : [[],[],[],[],[],[],[]],
    min_score : 0
  },
  {
    round : 4,
    floor : [[],[],[],[],[],[],[]],
    min_score : 0
  },
]

const lodash = require("lodash");

const round = (state = init_round, action) => {
  switch(action.type) {
    case "RESET_ROUND" :
      return init_round;
    case "SET_NUM" :
      const num = action.num;
      const init = []
      const tmp = []
      for (let i=0; i<=num - 2; i++) {
        tmp.push([])
      }
      for (let j = 1 ; j <=4 ;j++) {
        const round_item = {
          round : j,
          floor : lodash.cloneDeep(tmp)
        }
        init.push(lodash.cloneDeep(round_item))
      }
      return init;
    case "SET_FLOOR" :
      const idx = action.idx; // 플레이어 idx
      const floor = action.floor; // 내린 층
      const round = action.round - 1; // 현재 라운드
      const tmp_state = lodash.cloneDeep(state);
      tmp_state[round].floor[floor] = [...tmp_state[round].floor[floor], idx]
      return tmp_state;
    case "SET_MIN" :
      const min_sc = action.score;
      const min_round = action.round - 1; // 현재 라운드
      const min_tmp = lodash.cloneDeep(state);
      min_tmp[min_round].min_score = min_sc;
      return min_tmp;
    default :
      return state;
  }
}

export default round;