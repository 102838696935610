import './App.css';
import { Route, Routes } from 'react-router-dom';
import Hidden from './pages/Hidden';
import Home from './pages/Home';
import Result from './pages/Result';
import ResultRound from './pages/ResultRound';
import ResultPlayer from './components/ResultPlayer';
import Round from './pages/Round';
import Setting from './pages/Setting';
import { React } from 'react';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/setting' element={<Setting/>}/>
      <Route path='/round/:idx' element={<Round/>}/>
      <Route path='/round_result/:idx' element={<ResultRound/>}/>
      <Route path='/round_result_player/:idx' element={<ResultPlayer/>}/>
      <Route path='/hidden' element={<Hidden/>}/>
      <Route path='/result' element={<Result/>}/>
    </Routes>
  );
}

export default App;